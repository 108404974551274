import React from 'react';
import { Header, Image, Button, Form, Grid, Message, Segment } from 'semantic-ui-react';
//import FullscreenLoader from '../components/FullscreenLoader';
import logo from '../../hea_logo.png';
import { useContext, useState } from 'react';
import { ContextUserStore, Signin } from '../../stores/UserStoreProvider';
import { loginUser, useAuthState, useAuthDispatch } from '../../Context';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";


export const LoginPage = () => {
  const { login } = useAuth();
//  const dispatch = useAuthDispatch();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const canLogin = userName !== '' && password !== '';
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = useState('');

//  const { loading, errorMessage } = useAuthState(); //read the values of loading and errorMessage from context

  const handleSubmit = (event) => {
    event.preventDefault();
    //const login_data = new FormData(event.currentTarget);
    //console.log("login_data: ", login_data );
    const data = {
      username: userName,
      password: password
  };

    alert(JSON.stringify(data));
    try {
			let response = login(data);
			if (!response) {
        //setMsg(response.error.code);
        return;
      } 
			if (!response.data) {
        //setMsg(response.error.code);
        return;
      } 
			navigate("/dashboard");
		} catch (error) {
			console.log(error);
		}

  };

  return (
    <div>
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column mobile={14} tablet={10} computer={6}>
          <Image size='medium' centered src={logo} />
          <Header as='h2' color='teal' textAlign='center'>
            Log In  
          </Header>
          {
              errorMessage ? <p>{errorMessage}</p> : null
          }

          <Form size='large' onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Input 
              fluid icon='user' 
              iconPosition='left' 
              placeholder='E-mail' 
              value={userName}
              onChange={e => setUserName(e.target.value)}
              />
              <Form.Input 
              fluid icon='lock' 
              iconPosition='left' 
              placeholder='Password' 
              type='password' 
              value={password}
              onChange={e => setPassword(e.target.value)}
              />
              <Button 
                disabled={!canLogin}
                color='teal' 
                fluid size='large'
              >
                Login
              </Button>
            </Segment>
          </Form>

          <Message>
            New User? <a href='#'>Sign Up</a>
          </Message>
        </Grid.Column>
      </Grid>
    </div>

  )
}
