import React from 'react';
import { Header, Image, Button, Form, Grid, Message, Segment, Label } from 'semantic-ui-react';
//import FullscreenLoader from '../components/FullscreenLoader';
import logo from '../../hea_logo.png';
import { useContext, useState } from 'react';
import { ContextUserStore, Signin } from '../../stores/UserStoreProvider';
import { loginUser, useAuthState, useAuthDispatch } from '../../Context';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import restService from '../../services/RestService';
import { FileUpload, FileType } from '../../Components/FileUpload';


export const PostAdd = () => {
  const [postName, setPostName] = useState('');
  const [postDescription, setPostDescription] = useState('');
  const [storyId, setStoryId] = useState(null);
    const [imageId, setImageId] = useState(null);
    const { login } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = useState('');

  const handleSubmit = (event) => {
      event.preventDefault();
    const data = {
      post_name: postName,
      post_description: postDescription,
      fk_post_image_id: imageId
    };
      return restService
        .savePost(data)
        .then(response => {
            if (response && response.status === 200) {
                navigate("/dashboard/posts", { replace: true });
            } else {
                throw new Error('Failed to login user');
            }
        })
        .then(response => {
          //setToken(response.data.data);
        })
        //.then(fetchUserInfo)
        .catch(restService.handleError);
  };

  function onUploadComplete(fileType, data) {
    let stateChange;
    switch (fileType) {
        case FileType.Audio:
            stateChange = {
                recordingId: data.recording_id
            };
            break;
        case FileType.Image:
            setImageId(data.post_image_id);
//            stateChange = {
//                imageId: data.post_image_id
//            };
            break;
        default:
            throw new Error('Unknown file type');
    }
    //this.setState(stateChange);
}


  return (
    <div>
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column mobile={14} tablet={10} computer={6}>
          <Image size='medium' centered src={logo} />
          <Header as='h2' color='teal' textAlign='center'>
            Lägg till artikel  
          </Header>
          {
              errorMessage ? <p>{errorMessage}</p> : null
          }

          <Form size='large' onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Input 
              fluid icon='file' 
              iconPosition='left'
              label='Namn/Rubrik (kommer också generera sökvägen till artikeln)'
              labelPosition='left'
              placeholder='Namn/Rubrik' 
              value={postName}
              onChange={e => setPostName(e.target.value)}
              />
              <Form.TextArea 
              fluid icon='file' 
              iconPosition='left' 
              label='Beskrivning kommer att synas som ingress till artikeln'
              labelPosition='left'
              placeholder='Beskrivning' 
              value={postDescription}
              onChange={e => setPostDescription(e.target.value)}
              />

            <FileUpload
                accept=".mp3,audio/*,image/png,image/jpeg"
                acceptUserFriendly="mp3 / png / jpg"
                placeholder="Droppa en fil här eller klicka för att välja"
                onCompleteNotifier={onUploadComplete}
                storyId={storyId}
                uploadEntity="Story"
            />

              <Button 
                color='teal' 
                fluid size='large'
              >
                Spara artikel (opublicerad)
              </Button>
            </Segment>
          </Form>

        </Grid.Column>
      </Grid>
    </div>

  )
}
