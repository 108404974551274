// Pages/Posts/index.js
 
import React from 'react';
import {Outlet} from 'react-router-dom';
import styles from './posts.module.css';
 
function Posts() {
    return (
      <div style={{ padding: 20 }}>
        <h2>Posts</h2>
        <Outlet />
      </div>
    );
  }

export default Posts;