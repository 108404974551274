import axios from 'axios';
import { getToken } from '../stores/UserStoreProvider';
//import { useAuth } from "../../hooks/useAuth";

class RestService {

    getApiBaseUrl() {
//        return 'https://st.mrsta.se/index.php';
        return 'http://heaserver/rest';
    }

    //
    // AUTHENTICATION
    //
    login(data) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/user/login`,
            data
        });
    }

    logout() {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/user/logout`
        });
    }

    signUp(data) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/user/create`,
            headers: { 'ST-TOKEN': getToken },
            data
        });
    }

    // POSTS
    getPosts() {
        const tok = getToken();
        console.log("Tok:",tok);
        return axios({
            method: 'get',
            url: `${this.getApiBaseUrl()}/posts`,
            headers: { 'ST-TOKEN': tok }
        });
    }

    getPost(id) {
        return axios({
            method: 'get',
            url: `${this.getApiBaseUrl()}/post/${id}`,
            headers: { 'ST-TOKEN': getToken() }
        });
    }

    savePost(data) {
        const tok = getToken();
        console.log("Tok:",tok);
//        console.log("savePost data:", data );
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/post/create`,
            data,
            headers: {
                'ST-TOKEN': tok
            }
        });
    }



    //
    // PLAYLISTS
    //
    getMyPlaylists() {
        return axios({
            method: 'get',
            url: `${this.getApiBaseUrl()}/my/playlists`,
            headers: { 'ST-TOKEN': getToken }
        });
    }

    //
    // STORIES
    //
    getMyStories() {
        return axios({
            method: 'get',
            url: `${this.getApiBaseUrl()}/my/stories`,
            headers: { 'ST-TOKEN': getToken }
        });
    }

    getStory(id) {
        return axios({
            method: 'get',
            url: `${this.getApiBaseUrl()}/story/${id}`,
            headers: { 'ST-TOKEN': getToken }
        });
    }

    getAutoStory(data) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/story/auto`,
            data,
            headers: { 'ST-TOKEN': getToken }
        });
    }

    deleteStory(id) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/story/delete/${id}`,
            headers: { 'ST-TOKEN': getToken }
        });
    }

    getStories(data) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/stories`,
            data,
            headers: { 'ST-TOKEN': getToken }
        });
    }

    saveStory(data) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/story/create`,
            data,
            headers: {
                'ST-TOKEN': getToken
            }
        });
    }

    likeStory(data) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/story/like`,
            data,
            headers: {
                'ST-TOKEN': getToken
            }
        });
    }

    themes() {
        return axios({
            method: 'get',
            url: `${this.getApiBaseUrl()}/themes`,
            headers: { 'ST-TOKEN': getToken }
        });
    }

    //
    // USER
    //
    getContextUser() {
        const tok = getToken();

        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/my/profile`,
            headers: {
                'ST-TOKEN': tok
            }
        });
    }

    updateContextUser(data) {
        console.log('updateContextUser', data);
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/my/profile/update`,
            data,
            headers: {
                'ST-TOKEN': getToken
            }
        });
    }

    getMyFollowers() {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/my/followers`,
            headers: {
                'ST-TOKEN': getToken
            }
        });
    }

    follow(data) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/user/follow`,
            data,
            headers: {
                'ST-TOKEN': getToken
            }
        });
    }

    //
    // SEARCH
    //
    search(data) {
        return axios({
            method: 'post',
            url: `${this.getApiBaseUrl()}/search`,
            headers: { 'ST-TOKEN': getToken },
            data
        });
    }

    //
    // PROFILES
    //
    getProfile(id) {
        return axios({
            method: 'get',
            url: `${this.getApiBaseUrl()}/user/profile/${id}`,
            headers: { 'ST-TOKEN': getToken }
        });
    }

    //
    // ERROR HANDLING
    //
    handleError(error) {
        console.log('restService.handlerError()', error);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error);
            alert(error.response.data.error.message_body);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
    }
}

export default new RestService();
