// Pages/Posts/index.js
 
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './posts.module.css';
import restService from '../../services/RestService';
import {useState, useEffect} from 'react';
import {Header, Message, Table, Button, Card, Image} from 'semantic-ui-react';
import { getToken } from '../../stores/UserStoreProvider';
import parse from 'html-react-parser';

function PostList() {
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate(); 
  useEffect(() => {
    if (!posts) {
    setLoading(true);
    restService
      .getPosts()
      .then(response => {
          const posts = response.data.data;
          setPosts(posts);
      })
      .catch(
        restService.handleError
      )
      .then(() => {
        setLoading(false);
      });

  }
},[posts]);

const routeChange = () =>{ 
  let path = '/dashboard/posts/add'; 
  navigate(path);
};

function handleNavigate(targetPath) {
    navigate(targetPath);
};

function getImageUrl(fk_post_image_id, id) {
  const hasImage = fk_post_image_id !== null;
  const token = getToken();
  if (hasImage) {
      return `http://heaserver/show_image.php?post_image_id=${fk_post_image_id}&token=${token}`;
  } else {
      return '/img/white-image.png';
  }
}

  return (
    <div>
      {
        //<Header as="h1">My Movies</Header>
      }
      {loading && <Message info header="Laddar inlägg..." />}
      {
      posts && posts.map && 
          <div>


              <div style={{ padding: 30, textAlign: 'center' }}>
                <p></p>
                <Card.Group ui centered stackable>
                {posts.map(
                              post => 
                    <Card 
                      raised 
                      image={getImageUrl(`${post.fk_post_image_id}`,`${post.post_id}`)}
                      style={{ padding: '10px'}} 
                      header={post.post_name} 
                      meta={post.update_time} 
                      centered 
                      description={parse(`${post.post_description}`)} 
                      link 
                      onClick={() => handleNavigate(`/dashboard/posts/${post.post_id}`)} 
                    >
                      </Card>


                )}
                </Card.Group>
              </div>
          </div>

          
      }
      <Button loading={loading} onClick={routeChange}>Add Post</Button>
    </div>
  );
}
export default PostList;

/*
              <Table>
                  <thead>
                      <tr>
                          <th>ID</th>
                          <th>Title</th>
                          <th>Actions</th>
                      </tr>
                  </thead>
                  <tbody>
                  {posts.map(
                          post => 
                              <tr id={post.post_id} key={post.post_id}>
                                  <Link to={`/dashboard/posts/${post.post_id}`}>
                                    <td>{post.post_id}</td>
                                  </Link>
                                  <td>{post.post_name}</td>
                                  <td>
                                      Edit
                                  </td>
                              </tr>
                  )}
                  </tbody>
              </Table>

*/

/*
                      <Card 
                        raised
                        centered
                        link
                        onClick={() => handleNavigate(`/dashboard/posts/${post.post_id}`)} 
                        >
                        <Card.Content>
                          <Image
                            src={getImageUrl(`${post.fk_post_image_id}`,`${post.post_id}`)}
                          />
                          <Card.Header>{post.post_name}</Card.Header>
                          <Card.Meta>{post.update_time}</Card.Meta>
                          <Card.Description>
                            {parse(`${post.post_description}`)}
                          
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <div className='ui two buttons'>
                            <Button basic color='green'>
                              Approve
                            </Button>
                            <Button basic color='red'>
                              Decline
                            </Button>
                          </div>
                        </Card.Content>
                      </Card>                      

*/