import React from 'react'
import { createContext, useContext, useEffect } from 'react';
import restService from '../services/RestService';
import Login from "../Login";

const ContextUserStore = createContext(null);


export function Signin(data) {
    return restService
        .login(data)
        .then(response => {
            if (response && response.status === 200) {
                return response;
            } else {
                throw new Error('Failed to login user');
            }
        })
        .then(response => {
            DoSetToken(response.data.data);
        })
        //.then(fetchUserInfo)
        .catch(restService.handleError);
}

function signout() {
    this.clear(); // ok to sign out even if the logout fails (how can it fail) on the server.
    return restService
        .logout()
        .then(response => {
            if (response) {
                if (response.status !== 200) {
                    alert('Logout failed on server');
                    console.error('Failed to logout user', response);
                }
            } else {
                console.error('Failed to logout user');
            }
        })
        .catch(restService.handleError);
}

function isAuthenticated() {
    console.log('isAuthenticated called');
    return sessionStorage.getItem('sessionToken') !== null && sessionStorage.getItem('sessionToken') !== '';
}

function userId() {
    return this.info ? this.info.fk_user_id : null;
}

function presentationName() {
    return this.info ? this.info.user_profile_name : null;
}

function presentation() {
    return this.info ? this.info.presentation : null;
}

function email() {
    return this.info ? this.info.user_email : null;
}

/**
 * Set information about the context user, e.g. user name, default recording language.
 * @param info User information
 */
function setInfo(info = {}) {
    //this.info = info;
    sessionStorage.setItem('contextUserInfo', JSON.stringify(info));
}

function fetchUserInfo() {
    return restService
        .getContextUser()
        .then(response => {
            const userInfo = response.data.data;
            setInfo(userInfo);
            //this.setAvatarUrl(Date.now());
        })
        .catch(restService.handleError);
};

/**
 * Sets the session token for context user.
 * @param token Session token
 */
 function DoSetToken(token) {
    //this.token = token;
    console.log("setToken called");
    sessionStorage.setItem('token', token);
}

function getToken() {
    return sessionStorage.getItem('token');
}

/**
 * Clears all information about the context user.
 */
function clear() {
    this.token = null;
    this.info = null;
    this.avatarUrl = null;
    sessionStorage.removeItem('sessionToken');
    sessionStorage.removeItem('contextUserInfo');
    sessionStorage.removeItem('avatarUrl');
}

const UserStoreProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = React.useState(null);
    //sessionStorage.getItem('sessionToken')

    //this.info = JSON.parse(sessionStorage.getItem('contextUserInfo'));
    //this.avatarUrl = sessionStorage.getItem('avatarUrl');
    useEffect(() => {
		const checkLoggedIn = async () => {
			let cuser = isAuthenticated();
			if (cuser === null) {
				sessionStorage.setItem('sessionToken', '');
				cuser = '';
			}

			setCurrentUser(cuser);
		};

		checkLoggedIn();
	}, []);

	console.log('usercontext', currentUser);

    return (
      <ContextUserStore.Provider value={[currentUser, setCurrentUser]}>
        {currentUser?.token ? children : <Login />}
      </ContextUserStore.Provider>
    );
  };

export { UserStoreProvider, ContextUserStore, getToken };
