import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
//import { ContextUserStore, Signin } from '../stores/UserStoreProvider';
import restService from '../services/RestService';

const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("token", null);
  const navigate = useNavigate();

  const Signin = async (data) => {
    return restService
        .login(data)
        .then(response => {
            if (response && response.status === 200) {
                return response;
            } else {
                throw new Error('Failed to login user');
            }
        })
        .then(response => {
          setToken(response.data.data);
        })
        //.then(fetchUserInfo)
        .catch(restService.handleError);
  }
  

  const login = async (data) => {
    var resp = Signin(data);
    console.log('login resp:', resp);
    setUser(data);
    navigate("/dashboard/home", { replace: true });
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      token,
      login,
      logout
    }),
    [user,token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
