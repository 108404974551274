import React, { useEffect, useRef } from 'react';
import { Header, Image, Button, Form, Grid, Message, Segment, Breadcrumb } from 'semantic-ui-react';
//import FullscreenLoader from '../components/FullscreenLoader';
import logo from '../../hea_logo.png';
import { useContext, useState } from 'react';
import { ContextUserStore, Signin } from '../../stores/UserStoreProvider';
import { loginUser, useAuthState, useAuthDispatch } from '../../Context';
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import restService from '../../services/RestService';
import { FileUpload, FileType } from '../../Components/FileUpload';
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';

const sections = [
  { key: 'dashboard', content: 'Home', link: true, href: '/' },
  { key: 'posts', content: 'Artiklar', link: true, href: '/dashboard/posts' },
  { key: 'EditPost', content: 'Ändra artikel', active: true },
]

const BreadcrumbExampleShorthand = () => {
  return (
    <Breadcrumb icon='right angle' sections={sections} />
  );
}

export const PostEdit = () => {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };


  const { post_id } = useParams();

  const [post, setPost] = useState();
  const [loading, setLoading] = useState(false);
  const [postName, setPostName] = useState('');
  const [postDescription, setPostDescription] = useState('');
  const [postText, setPostText] = useState('');
    const [storyId, setStoryId] = useState(null);
    const [imageId, setImageId] = useState(null);
    const { token } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = useState('');

  useEffect(() => {
    if (!post) {
    setLoading(true);
    restService
      .getPost(post_id)
      .then(response => {
          const post = response.data;
//          console.log("post:", response.data);
          setPost(post);
          setPostName(post.post_name);
          setPostDescription(post.post_description);
          setImageId(post.fk_post_image_id);
          editorRef.current.setContent(post.post_text);
      })
      .catch(
        restService.handleError
      )
      .then(() => {
        setLoading(false);
      });

  }
},[post, post_id]);





  const handleSubmit = (event) => {
      event.preventDefault();
    const data = {
        post_id: post_id,
        post_name: postName,
        post_description: postDescription,
        post_text: editorRef.current.getContent(),
        fk_post_image_id: imageId
    };
      return restService
        .savePost(data)
        .then(response => {
            if (response && response.status === 200) {
                navigate("/dashboard/posts", { replace: true });
            } else {
                throw new Error('Failed to login user');
            }
        })
        .then(response => {
          //setToken(response.data.data);
        })
        //.then(fetchUserInfo)
        .catch(restService.handleError);
  };

  function onUploadComplete(fileType, data) {
    let stateChange;
    switch (fileType) {
        case FileType.Audio:
            stateChange = {
                recordingId: data.recording_id
            };
            break;
        case FileType.Image:
            setImageId(data.post_image_id);
//            stateChange = {
//                imageId: data.post_image_id
//            };
            break;
        default:
            throw new Error('Unknown file type');
    }
    //this.setState(stateChange);
}

function getImageUrl(fk_post_image_id, id) {
  const hasImage = fk_post_image_id !== null;
  if (hasImage) {
      return `http://heaserver/show_image.php?post_image_id=${fk_post_image_id}&token=${token}`;
  } else {
      return '/img/white-image.png';
  }
}

  return (
    <div>
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column mobile={14} tablet={10} computer={6}>
          <Image size='medium' centered src={logo} />
    {BreadcrumbExampleShorthand()}
          <Header as='h2' color='teal' textAlign='center'>
            Ändra inlägg  
          </Header>
          {
              errorMessage ? <p>{errorMessage}</p> : null
          }
      {loading && <Message info header="Laddar inlägg..." />}
      {
      post && 

          <Form size='large' onSubmit={handleSubmit}>
            <Segment stacked>
              <Image src={getImageUrl(`${post.fk_post_image_id}`,`${post.post_id}`)} />
            <FileUpload
                accept=".mp3,audio/*,image/png,image/jpeg"
                acceptUserFriendly="mp3 / png / jpg"
                placeholder="Om du vill byta bild, klicka eller droppa en fil här"
                onCompleteNotifier={onUploadComplete}
                storyId={storyId}
                uploadEntity="Story"
            />
              <Form.Input 
              fluid icon='file' 
              iconPosition='left' 
              label='Namn/Rubrik (kommer också generera sökvägen till artikeln)'
              labelPosition='left'
              placeholder='Namn/Rubrik' 
              value={postName}
              onChange={e => setPostName(e.target.value)}
              />
              <Form.TextArea 
              fluid icon='file' 
              iconPosition='left' 
              label='Beskrivning kommer att synas som ingress till artikeln'
              labelPosition='left'
              placeholder='Beskrivning' 
              value={postDescription}
              onChange={e => setPostDescription(e.target.value)}
              />
                            
                            <Editor
        apiKey='ehmay8imn7gizkcf7w67bqjwlirqyvw5rvcz6se6pm549kq6'
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={post.post_text}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'emoticons', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | emoticons link | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
              <Button 
                color='teal' 
                fluid size='large'
              >
                Spara ändrat inlägg
              </Button>
            </Segment>
          </Form>
        }
        </Grid.Column>
      </Grid>
    </div>

  )
}
