// Pages/Dashboard/index.js
 
import React from 'react'
//import { useAuthDispatch, logout, useAuthState } from '../../Context';
import styles from './dashboard.module.css';
import { AppBarRenderer } from '../../Components/AppBar/AppBarRenderer';
import { Grid, Card, Container, Icon } from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const extraMyAccount = (
    <div style={{textAlign: "center"}}>
  <Icon.Group size='big'>
    <Icon circular inverted color='purple' name='user' />
    <Icon corner circular bordered inverted color='purple' name='info' />
  </Icon.Group>
        <p style={{color: "black", fontSize: "large"}}>Mitt konto</p>
    </div>
);


const extraCustomer = (
    <div style={{textAlign: "center"}}>
        <Icon circular inverted color='blue' name='users' size='big' />
        <p style={{color: "black", fontSize: "large"}}>Kunder</p>
    </div>
);

const extraInvoice = (
    <div style={{textAlign: "center"}}>
        <Icon circular inverted color='green' name='file alternate outline' size='big' />
        <p style={{color: "black", fontSize: "large"}}>Fakturor</p>
    </div>
);

const extraPost = (
    <div style={{textAlign: "center"}}>
        <Icon circular inverted color='yellow' name='bullhorn' size='big' />
        <p style={{color: "black", fontSize: "large"}}>Nyheter</p>
    </div>
);

const extraSettings = (
    <div style={{textAlign: "center"}}>
        <Icon circular inverted color='orange' name='settings' size='big' />
        <p style={{color: "black", fontSize: "large"}}>Inställningar</p>
    </div>
);

const extraLogout = (
    <div style={{textAlign: "center"}}>
        <Icon circular inverted color='red' name='sign-out' size='big' />
        <p style={{color: "black", fontSize: "large"}}>Logga ut</p>
    </div>
);



export function Dashboard() {
    //const dispatch = useAuthDispatch(); // read dispatch method from context
    //const userDetails = useAuthState(); //read user details from context
    const navigate = useNavigate();
    const { logout } = useAuth();

    function handleNavigate(targetPath) {
        navigate(targetPath);
    };
 
    const handleLogout = () => {
        logout(); //call the logout action
        
        //props.history.push('/login') //navigate to logout page on logout
    };
    return (
        <div style={{ padding: 30, textAlign: 'center' }}>
<p></p>
            <Card.Group ui stackable>
            <Card raised style={{ backgroundColor: '#FFEEFF'}} header={extraMyAccount} meta='hoppsan' centered description='Hallå' link onClick={() => handleNavigate('/customers')} />
            <Card raised style={{ backgroundColor: '#EEEEFF'}} header={extraCustomer} meta='hoppsan' centered description='Hallå' link onClick={() => handleNavigate('/customers')} />
    <Card raised style={{ backgroundColor: '#EEFFEE'}} header={extraInvoice} meta='hoppsan' centered description='Hallå' link onClick={() => handleNavigate('/invoices')} />
    <Card raised style={{ backgroundColor: '#FFFFEE'}} header={extraPost} meta='hoppsan' centered description='Hallå' link onClick={() => handleNavigate('/dashboard/posts')} />
    <Card raised style={{ backgroundColor: '#FFEFDD'}} header={extraSettings} meta='hoppsan' centered description='Hallå' link onClick={() => handleNavigate('/posts')} />
    <Card raised style={{ backgroundColor: '#FFEEEE'}} header={extraLogout} centered link onClick={() => handleLogout()} />
  </Card.Group>
        </div>
    )
}
 
