import React from 'react';
import AppNews from './AppNews';
import { AuthProvider } from "./hooks/useAuth";
import { BrowserRouter } from "react-router-dom";

const AppWithNews = () => (
    <BrowserRouter>
        <AuthProvider>
            <AppNews />
        </AuthProvider>
    </BrowserRouter>

);

export default AppWithNews;
