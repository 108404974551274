import { useNavigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBarRenderer } from "./AppBar/AppBarRenderer";
import { useEffect } from "react";

export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (user) {
        return navigate("/dashboard/home", { replace: true });
        //return <Navigate to="/dashboard/profile" replace />;
    }
  });

  return (
    <div>
      <AppBarRenderer
        pages={[
          { label: "Home", path: "/" },
          { label: "Login", path: "/login" }
        ]}
      />
      {outlet}
    </div>
  );
};
