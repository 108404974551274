// Pages/Posts/index.js
 
import React from 'react';
import {useParams} from 'react-router-dom';
import {Link, useNavigate} from 'react-router-dom';
import restService from '../../services/RestService';
import {useState, useEffect} from 'react';
import {Header, Message, Table, Button, Card} from 'semantic-ui-react';
import { getToken } from '../../stores/UserStoreProvider';
import parse from 'html-react-parser';
 
function Post() {
  const { post_id } = useParams();

  const [post, setPost] = useState();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate(); 
  useEffect(() => {
    if (!post) {
    setLoading(true);
    restService
      .getPost(post_id)
      .then(response => {
          const post = response.data;
//          console.log("post:", response.data);
          setPost(post);
      })
      .catch(
        restService.handleError
      )
      .then(() => {
        setLoading(false);
      });

  }
},[post, post_id]);

const routeChange = () =>{ 
  let path = '/dashboard/posts/add'; 
  navigate(path);
};

function handleNavigate(targetPath) {
    navigate(targetPath);
};

function getImageUrl(fk_post_image_id, id) {
  const hasImage = fk_post_image_id !== null;
  const token = getToken();
  if (hasImage) {
      return `http://heaserver/show_image.php?post_image_id=${fk_post_image_id}&token=${token}`;
  } else {
      return '/img/white-image.png';
  }
}

  return (
    <div style={{ padding: 30, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'  }}>
      {
        //<Header as="h1">My Movies</Header>
      }
      {loading && <Message info header="Laddar inlägg..." />}
      {
      post && 
              <div style={{ padding: 30, textAlign: 'center' }}>
                <p></p>
                <Card.Group ui centered stackable>
                  <div>
                    <Card 
                      raised 
                      image={getImageUrl(`${post.fk_post_image_id}`,`${post.post_id}`)}
                      header={post.post_name} 
                      meta={post.update_time} 
                      centered 
                      description={ parse(`${post.post_description}<p>${post.post_text}</p>`)} 
                      link 
                      onClick={() => handleNavigate(`/dashboard/posts/${post.post_id}`)} 
                    />
                  </div>
                </Card.Group>
              </div>

          
      }
      <Button loading={loading} onClick={() => handleNavigate(`/dashboard/posts/edit/${post.post_id}`)}>Edit Post</Button>
    </div>
  );

}

export default Post;