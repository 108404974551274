import React, { useState, useCallback } from 'react';
import { Progress } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
//import '../../css/FileUpload.css';
import { useAuth } from "../hooks/useAuth";


const UploadStatus = {
    InProgress: 'inprogress',
    Success: 'success',
    Error: 'error'
};

export const FileType = {
    Audio: {
        service: 'http://heaserver/upload.php'
    },
    Image: {
        service: 'http://heaserver/upload_image.php'
    },
    ImageProfile: {
        service: 'http://heaserver/upload_avatar.php'
    },
    Unknown: {},
    identify: (file, entity) => {
        if (!file.type) {
            return FileType.Unknown;
        }
        const fileInfo = file.type.split('/');
        switch (fileInfo[0]) {
            case 'image':
                if (entity === 'Avatar') return FileType.ImageProfile;
                else return FileType.Image;
            case 'audio':
                return FileType.Audio;
            default:
                return FileType.Unkonwn;
        }
    }
};

export function FileUpload ({accept, acceptUserFriendly, placeholder, onCompleteNotifier, storyId, uploadEntity}) {

    const [progress, setProgress] = useState(null);
    const [status, setStatus] = useState(null);
    const { token } = useAuth();

      const onDrop = useCallback(files => {

        files.forEach(file => {
            const fileType = FileType.identify(file, uploadEntity);

            //const { token } = this.props.contextUserStore;
            //const { token } = token;
            console.log('fileType', fileType);

            // Uploading - for Firefox, Google Chrome and Safari
            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', onProgress, false);
            xhr.addEventListener('load', () => onComplete(xhr, fileType), false);

            xhr.open('post', fileType.service, true);

            // Set appropriate headers
            //xhr.setRequestHeader('Content-Type', 'multipart/form-data');
            xhr.setRequestHeader('X-File-Name', file.name);
            xhr.setRequestHeader('X-File-Size', file.size);
            xhr.setRequestHeader('X-File-Type', file.type);
//            if (uploadEntity === 'Avatar') {
//                xhr.setRequestHeader('ST-PROFILE-ID', profileId);
//            } else {
                xhr.setRequestHeader('ST-IMAGE-ID', storyId);
//            }
            xhr.setRequestHeader('ST-TOKEN', token);

            // Send the file (doh)
            xhr.send(file);
        });
    });

    function onComplete(xhr, fileType) {
        const json = JSON.parse(xhr.responseText);
        if (json.error) {
            setStatus(UploadStatus.Error);
        } else {
            setStatus(UploadStatus.Success);
            onCompleteNotifier(fileType, json);
        }
    }

    function onProgress(event) {
        if (!event.lengthComputable) {
            return;
        }
        const progress = Math.round(event.loaded / event.total * 100);
        setProgress(progress);
    }

    function renderProgress() {
        if (status === null) {
            return null;
        }
        const isSuccess = status === UploadStatus.Success;
        const isError = status === UploadStatus.Error;
        return (
            <div className="progress-wrapper">
                <Progress
                    percent={progress}
                    indicating
                    progress
                    success={isSuccess}
                    error={isError}
                >
                    {isSuccess && 'Upload successful'}
                    {isError && 'Upload failed'}
                </Progress>
            </div>
        );
    };

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      ));
    

//    render() {
        return (
            <div className="file-upload">
                {renderProgress()}

                <section className="container">
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p>{placeholder}</p>
                    </div>
                    <aside>
                        <h4>Files</h4>
                        <ul>{files}</ul>
                    </aside>
                </section>

            </div>
        );
//    }
}


