import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from "./Pages/Login";
//import { HomePage } from "./pages/Home";
//import { ProfilePage } from "./pages/Profile";
//import { SettingsPage } from "./pages/Settings";
import { ProtectedLayout } from "./Components/ProtectedLayout";
import { Dashboard } from "./Pages/Dashboard";
import { HomeLayout } from "./Components/HomeLayout";
//import "./styles.css";
import Posts from "./Pages/Posts";
import PostList from "./Pages/Posts/PostList";
import Post from "./Pages/Posts/Post";
import { PostAdd } from "./Pages/Posts/PostAdd";
import { PostEdit } from "./Pages/Posts/PostEdit";

export default function App() {
  return (
    <div className="app">
        <Routes>
          {
            /*
          <Route element={<HomeLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
          </Route>

          <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="profile" element={<ProfilePage />} />
            <Route path="settings" element={<SettingsPage />} />
          </Route>
            */
          }
          <Route element={<HomeLayout />}>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
          </Route>

          <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="home" element={<Dashboard />} />
            <Route path="posts" element={<Posts />}>
              <Route index element={<PostList />} />
              <Route path=":post_id" element={<Post />} />
              <Route path="add" element={<PostAdd />} />
              <Route path="edit">
                <Route path=":post_id" element={<PostEdit />} />
              </Route>
            </Route>
          </Route>

        </Routes>
    </div>
  );
}
