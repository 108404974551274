import { Link, useNavigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBarRenderer } from "../Components/AppBar/AppBarRenderer";
import { useEffect } from "react";

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  const navigate = useNavigate();
useEffect(() => {
  if (!user) {
      return navigate("/", { replace: true });
//    return <Navigate to="/" />;
  }
});

  return (
    <div>
      <AppBarRenderer
        pages={[
          { label: "Settings", path: "settings" },
          { label: "Profile", path: "profile" }
        ]}
      />
      {outlet}
    </div>
  );
};
